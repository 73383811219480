import { NewspaperIcon } from '@heroicons/react/outline';

export default function About() {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg row-span-1 col-span-3 xl:col-span-2">
      <div className="flex flex-col gap-3 px-4 py-5 sm:p-6">
        <div className="flex items-center gap-3">
          <NewspaperIcon className="w-12 h-12 flex-shrink-0" />
          <h2 className="text-2xl font-bold leading-7 text-gray-900">
            About the Catholic Teacher&apos;s Gazette
          </h2>
        </div>
        <p className="prose">
          Since its inception in 1994, Catholic Teacher’s Gazette has been a
          source of support and continuity to Catholic schools across England
          and Wales.
        </p>
        <p className="prose">
          Originally conceived by John and Ann Burns to provide an effective
          means of our schools advertising posts, CTG has evolved into a staple
          of Catholic school life. Today, your team at CTG is led by individuals
          with many years of experience in Catholic school leadership, teaching
          and governance, all committed to preserving and promoting excellence
          in Catholic education.
        </p>
        <p className="prose">
          We&apos;re really excited about what the future holds for CTG,
          ensuring the weekly publication and online platform meet your
          recruitment needs as well as developing other forums through which
          Catholic schools can attract the very best candidates. As always, we
          welcome your ideas and suggestions and look forward to growing the CTG
          community together, ever mindful of our shared gospel values.
        </p>
      </div>
    </div>
  );
}
