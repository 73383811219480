import {
  MailIcon,
  PhoneIcon,
  SpeakerphoneIcon,
} from '@heroicons/react/outline';

export default function Advertise() {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg row-span-1 col-span-3 xl:col-span-2">
      <div className="flex flex-col gap-3 px-4 py-5 sm:p-6">
        <div className="flex items-center gap-3">
          <SpeakerphoneIcon className="w-12 h-12 flex-shrink-0" />
          <h2 className="text-2xl font-bold leading-7 text-gray-900">
            Advertise with us
          </h2>
        </div>
        <p className="prose">
          To advertise in the Catholic Teachers Gazette, please send your copy
          by email. Enquiries are welcomed by phone or email.
        </p>
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-3">
            <MailIcon className="w-6 h-6" />
            <a
              className="text-indigo-600 hover:text-indigo-700"
              href="mailto:adverts@ctgonline.co.uk"
            >
              adverts@ctgonline.co.uk
            </a>
          </div>
          <div className="flex items-center gap-3">
            <PhoneIcon className="w-6 h-6" />
            <a
              className="text-indigo-600 hover:text-indigo-700"
              href="tel:01302239001"
            >
              01302 239 001
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
