import About from '../components/public/About';
import Advertise from '../components/public/Advertise';
import RecentJobs from '../components/public/RecentJobs';
import Layout from '../components/public/Layout';
import { fetchCategories } from '../lib/api/fetchCategories';
import prisma from '../lib/prisma';

export default function IndexPage({ categories, recentJobs }) {
  return (
    <Layout categories={categories}>
      <div className="grid gap-6 grid-cols-1 xl:grid-cols-3 grid-rows-[auto_auto]">
        <RecentJobs jobs={recentJobs} />
        <About />
        <Advertise />
      </div>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const categories = await fetchCategories();
  const recentJobs = await prisma.job.findMany({
    include: {
      school: true,
      position: { include: { category: true } },
    },
    where: {
      published: true,
      closingDate: { gte: new Date() },
    },
    orderBy: { createdAt: 'desc' },
    take: 5,
  });
  return {
    props: {
      categories: JSON.parse(JSON.stringify(categories)),
      recentJobs: JSON.parse(JSON.stringify(recentJobs)),
    },
  };
}
