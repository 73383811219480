import {
  AcademicCapIcon,
  CalendarIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid';
import { BriefcaseIcon } from '@heroicons/react/outline';
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns';
import Link from '../Link';

export default function RecentJobs({ jobs }) {
  return (
    <div className="bg-white shadow overflow-hidden rounded-md row-span-3 col-span-3 xl:col-span-1">
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="flex items-center gap-3">
          <BriefcaseIcon className="w-12 h-12 flex-shrink-0" />
          <h2 className="text-2xl font-bold leading-7 text-gray-900">
            Latest vacancies
          </h2>
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-200">
        {jobs.map((job) => (
          <li key={job.id}>
            <Link href={`/view/${job.id}`} className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    {job.title}
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="text-xs text-gray-500">
                      {formatDistanceToNowStrict(new Date(job.createdAt))} ago
                    </p>
                  </div>
                </div>
                <div className="mt-2">
                  <div>
                    <p className="flex items-center text-sm text-gray-500">
                      <AcademicCapIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {job.school.name}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <LocationMarkerIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {job.school.town}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <p>
                      Closing on{' '}
                      <time dateTime={job.closingDate}>
                        {format(parseISO(job.closingDate), 'MMMM d, yyyy')}
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
